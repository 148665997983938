<template>
	<v-container class="mt-4 px-5" fluid>
	  <v-row v-if="( getdatosUsuario.idpuesto == 18 && [568, 7, 161, 626, 382 ].includes( getdatosUsuario.iderp ) ) || getdatosUsuario.idpuesto != 18">
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  		    <span class="text-subtitle-1 pe-5">Visualizar entradas Teacher</span>
            
            <v-spacer></v-spacer>
	  		    <v-btn color="primary" dark class="mb-2" @click="getRol" small tile>
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
            
	  		  </v-card-title>

          <v-card-text>
            <v-col cols="6" v-if="ultimaActualizacion" class="pa-0">
                Última actualización
                <br/>Usuario: <b>{{ ultimaActualizacion.nombre_completo }}</b>
                <br/>Fecha: <b>{{ ultimaActualizacion.fecha_creacion }}</b>
            </v-col>
            <v-row class="pb-3">
              <v-col>
                <p>Fecha Actual: {{ fechaActual }}</p>
              </v-col>
              <v-col>
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  persistent-hint
                  single-line
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete> 
              </v-col>

            </v-row>

            <v-row>
              <v-col cols="4" v-for="(a, i) in planteles" :key="i" v-if="![13,15,16,17,18,19,20,27,28].includes(a.id_plantel)">
                <v-card class="elevation-12" height="100%">
                  <v-card-title primary-title>{{a.plantel}}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text align="start">
                    <p class="ma-0"
                    v-for="(b, i) in teachersFiltros" :key="i" v-if="b.id_plantel == a.id_plantel && b.teacher && b.teacher != 'SIN TEACHER'" >
                      <v-chip
                        dark small class="mt-2"
                        :color="b.asistencia ? 'green' : 'red'"
                        @click="(telefonos = [b.telefonos[0]]), dialogTelefonos=true" 
                        >
                        {{ b.teacher }}
                      </v-chip>
                    </p>
                  </v-card-text>
                <v-card-actions>
                </v-card-actions>
              </v-card>
              </v-col>
            </v-row>
              
            
  				  <v-dialog v-model="dialogTelefonos"max-width="900">
              <v-card>
                <v-card-title primary-title>
                  <v-icon left>mdi-phone</v-icon> Telefonos
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                  <v-data-table :headers="headersTelefonos" :items="telefonos" class="elevation-0" dense> 
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="error"   dark @click="dialogTelefonos = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>

				</v-card>
		  </v-col>
	  </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'


  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      fechaActual: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      dialogTelefonos: false,
      headers: [
        { text: 'ID GRUPO' , value: 'id_grupo', },
        { text: 'Grupo'    , value: 'grupo', },
        { text: 'Hora'     , value: 'hora_inicio' },
        { text: 'Teacher'  , value: 'teacher' },
      ],
      headersTelefonos: [
        { text: 'Nombre completo' , value: 'nombre_completo', },
        { text: 'Telefono 1'      , value: 'telefono1', },
        { text: 'Telefono 2'      , value: 'telefono2' },
      ],
      entradas: [],
      search:'',

      ciclos:[],
      ciclo:null,
      planteles:[],

      gruposRoles:[],
      ultimaActualizacion:[],
      teachersFiltros:[],
      idtrabajadoresTeachers:[],
      telefonos: []
    }),

    watch: {
      dialog (val) {
        val || this.close()
      },

      ciclo( val ){
        if( val ){ this.getRol() }
      }

    },

    async created () {
      await this.initialize()
    },

    computed: {
      ...mapGetters("login", ["getdatosUsuario", "getLogeado"]),
    },
    
    methods: {
      initialize () {
        this.entradas = []
        this.cargar = true
        //this.calcularFechaHoy()
        this.obtenerCiclos()
        this.getPlanteles()
        // this.getRol()
        //  return this.$http.get('asistencias.visuales.teachers').then(response=>{
        //  this.entradas = response.data
        //  console.log(this.entradas[0])
        //  }).catch( error =>{
        //     this.validarError( error.response.data.message )
        // }).finally( () => { })
      },

      calcularFechaHoy(){

      },

      getTelefonos( idteachers ){
        this.$http.post('teachers.telefonos', idteachers ).then(response=>{

          this.teachersFiltros.forEach(element => {
            element.telefonos = response.data.filter(el => el.id == element.id_teacher)
          });

        }).catch( error =>{
          console.log(error)
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getAsistencias( idteachers ){
        console.log(idteachers)
        return this.$http.get('asistencias.visuales.teachers').then(response=>{
          this.entradas = response.data
          console.log(this.entradas)
          this.teachersFiltros.forEach(element => {
            element.asistencia2 = response.data.find(el => el.id_usuario == element.id_teacher)
            element.asistencia = element.asistencia2 ? element.asistencia2.asistencia : 0
           });
          }).catch( error =>{
             this.validarError( error.response.data.message )
         }).finally( () => { })
        // return this.$http.post('teachers.presentes', idteachers).then(response=>{
        //   this.teachersFiltros.forEach(element => {
        //     element.asistencia2 = response.data.filter(el => el.id_usuario == element.id_teacher)
        //     element.asistencia = element.asistencia2[0] ? element.asistencia2[0].activo : 0
        //   });
        //   }).catch( error =>{
        //     console.log(error)
        //      this.validarError( error.response.data.message )
        //  }).finally( () => { })
      },

      getRol( ){
        this.cargar = true
        this.$http.post('rol.clases', this.ciclo ).then(response=>{
          this.gruposRoles         = response.data.respuesta
          this.ultimaActualizacion = response.data.ultimaActualizacion
          // Obtener los nombres de tooodos los teachers
          let tlu1 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.lu1_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.lu1, teacher: registro.lu1_n, id_plantel: registro.id_plantel, asistencia:registro.lu1_hora_asistencia }})
          let tma1 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.ma1_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.ma1, teacher: registro.ma1_n, id_plantel: registro.id_plantel, asistencia:registro.ma1_hora_asistencia }})//{ return registro.ma1_n })
          let tmi1 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.mi1_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.mi1, teacher: registro.mi1_n, id_plantel: registro.id_plantel, asistencia:registro.mi1_hora_asistencia }})//registro.mi1_n })
          let tju1 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.ju1_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.ju1, teacher: registro.ju1_n, id_plantel: registro.id_plantel, asistencia:registro.ju1_hora_asistencia }})//{ return registro.ju1_n })
          let tvi1 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.vi1_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.vi1, teacher: registro.vi1_n, id_plantel: registro.id_plantel, asistencia:registro.vi1_hora_asistencia }})//registro.vi1_n })

          let tlu2 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.lu2_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.lu2, teacher: registro.lu2_n, id_plantel: registro.id_plantel, asistencia:registro.lu2_hora_asistencia }})//{ return registro.lu2_n })
          let tma2 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.ma2_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.ma2, teacher: registro.ma2_n, id_plantel: registro.id_plantel, asistencia:registro.ma2_hora_asistencia }})//{ return registro.ma2_n })
          let tmi2 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.mi2_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.mi2, teacher: registro.mi2_n, id_plantel: registro.id_plantel, asistencia:registro.mi2_hora_asistencia }})//{ return registro.mi2_n })
          let tju2 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.ju2_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.ju2, teacher: registro.ju2_n, id_plantel: registro.id_plantel, asistencia:registro.ju2_hora_asistencia }})//{ return registro.ju2_n })
          let tvi2 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.vi2_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.vi2, teacher: registro.vi2_n, id_plantel: registro.id_plantel, asistencia:registro.vi2_hora_asistencia }})//{ return registro.vi2_n })

          let tlu3 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.lu3_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.lu3, teacher: registro.lu3_n, id_plantel: registro.id_plantel, asistencia:registro.lu3_hora_asistencia }})//{ return registro.lu3_n })
          let tma3 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.ma3_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.ma3, teacher: registro.ma3_n, id_plantel: registro.id_plantel, asistencia:registro.ma3_hora_asistencia }})//{ return registro.ma3_n })
          let tmi3 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.mi3_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.mi3, teacher: registro.mi3_n, id_plantel: registro.id_plantel, asistencia:registro.mi3_hora_asistencia }})//{ return registro.mi3_n })
          let tju3 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.ju3_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.ju3, teacher: registro.ju3_n, id_plantel: registro.id_plantel, asistencia:registro.ju3_hora_asistencia }})//{ return registro.ju3_n })
          let tvi3 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.vi3_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.vi3, teacher: registro.vi3_n, id_plantel: registro.id_plantel, asistencia:registro.vi3_hora_asistencia }})//{ return registro.vi3_n })

          let tlu4 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.lu4_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.lu4, teacher: registro.lu4_n, id_plantel: registro.id_plantel, asistencia:registro.lu4_hora_asistencia }})//{ return registro.lu4_n })
          let tma4 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.ma4_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.ma4, teacher: registro.ma4_n, id_plantel: registro.id_plantel, asistencia:registro.ma4_hora_asistencia }})//{ return registro.ma4_n })
          let tmi4 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.mi4_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.mi4, teacher: registro.mi4_n, id_plantel: registro.id_plantel, asistencia:registro.mi4_hora_asistencia }})//{ return registro.mi4_n })
          let tju4 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.ju4_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.ju4, teacher: registro.ju4_n, id_plantel: registro.id_plantel, asistencia:registro.ju4_hora_asistencia }})//{ return registro.ju4_n })
          let tvi4 = response.data.respuesta.filter( el => { return el.deleted == 0 && el.vi4_fecha == this.fechaActual }).map((registro) => { return { id_teacher: registro.vi4, teacher: registro.vi4_n, id_plantel: registro.id_plantel, asistencia:registro.vi4_hora_asistencia }})//{ return registro.vi4_n })

          this.teachersFiltros = tlu1.concat(tma1).concat(tmi1).concat(tju1).concat(tvi1).concat(tlu2).concat(tma2).concat(tmi2).concat(tju2).concat(tvi2).concat(tlu3).concat(tma3).concat(tmi3).concat(tju3).concat(tvi3).concat(tlu4).concat(tma4).concat(tmi4).concat(tju4).concat(tvi4).sort()


          // Concatenamos a los maestros
          //let arregloTeachers = tlu2.concat( tma2 ).concat( tmi2 ).concat( tju2 ).concat( tvi2 )

          // // Quitamos los duplicados de los maestros
          this.teachersFiltros = Array.from(new Set(this.teachersFiltros.map(JSON.stringify))).map(JSON.parse);
          this.idtrabajadoresTeachers = this.teachersFiltros.map(el => { return el.id_teacher })
          this.getTelefonos( this.idtrabajadoresTeachers )
          this.getAsistencias( this.idtrabajadoresTeachers )

        }).catch( error =>{
          console.log(error)
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      obtenerCiclos(){
        this.ciclos = []
        // Obtener todos los ciclos
        this.$http.get('roles.ciclos.all').then(response=>{
          // hacer un ciclo ya que los ciclos vienen varios como inscripciones y todo eso
          // y solo queremos los ciclos que en su nombre dicen ciclo 
          for(const i in response.data){
            //  si es diferente a FE
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              // Y es igual a FE, se agrega a ciclos
              // por qué es diferente a FE? por que es por estetica, mostrar solo los ciclos normales, ya que los ciclos tienen ligado el ciclo FE
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar      = false})
      },
      
      editItem (item) {

      	this.alumno = item

       	if( item.escuela == 2 )
       		this.urlDirecta = this.urlFast + item.nombre_video
       	else
       		this.urlDirecta = this.urlInbi + item.nombre_video

        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
    },
  }
  /*454*/
</script>